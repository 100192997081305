<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card" ref="domain_list">
          <div class="card-header">
            <h4 class="card-title">
              <i class="ion ion-clipboard mr-1"></i>
              {{ $trans.get('domainmainpage.lbl_title') }}
            </h4>
          </div>
          <div class="card-body">
            <table data-v-3ed4df82="" role="table" aria-busy="false" aria-colcount="4" class="table b-table projects table-striped">
              <thead role="rowgroup" class="">
                <tr role="row" class="">
                  <th role="columnheader" scope="col" aria-colindex="1" class="" style="width: 50%;">
                    <span>{{ $trans.get('domainmainpage.lbl_name') }}</span>
                  </th>
                  <th role="columnheader" scope="col" aria-colindex="2" class="" style="width: 15%;">
                    <span>{{ $trans.get('global.lbl_status') }}</span>
                  </th>
                  <th role="columnheader" scope="col" aria-colindex="3" class="text-center" style="width: 35%;">
                    <span>{{ $trans.get('global.lbl_actions') }}</span>
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <tr role="row" v-for="(domain, index) in domains">
                    <td aria-colindex="1" role="cell"> {{ domain.fqdn }}</td>
                    <td aria-colindex="2" role="cell"> <span class="badge badge-danger"><div>Inactive</div></span></td>
                    <td aria-colindex="3" role="cell" class="text-center">
                      <div role="toolbar" class="btn-toolbar" aria-label="Toolbar Actions">
                        <div aria-label="view" role="group" class="mr-1 btn-group">
                          <a @click="getVersion(domain.fqdn)" target="_self" class="btn btn-primary btn-sm">
                            <i class="fas fa-folder"></i> {{ $trans.get('domainmainpage.btn_download') }}
                          </a>
                        </div>
                        <div aria-label="download" role="group" class="mr-1 btn-group">
                          <a @click="downloadInstaller(domain.fqdn)" target="_self" class="btn btn-primary btn-sm">
                            <i class="fas fa-folder"></i> {{ $trans.get('domainmainpage.btn_download') }}
                          </a>
                        </div>
                        <div aria-label="edit" role="group" class="mr-1 btn-group" v-if="hasEditRight">
                          <button name="edit" type="button" class="btn btn-info btn-sm">
                            <i class="fas fa-edit"></i> {{ $trans.get('global.lbl_btn_edit') }}
                          </button>
                        </div>
                        <div aria-label="delete" role="group" class="btn-group" v-if="hasEditRight">
                          <button name="delete" data-toggle="confirmation" data-guid="b5d816cd-d086-4795-b2fe-397a136f3514" type="button" class="btn btn-danger btn-sm" data-original-title="" title="">
                            <i class="fas fa-trash"></i> {{ $trans.get('global.lbl_btn_delete') }}
                          </button>
                        </div>
                      </div>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Form, Field } from 'vee-validate';

export default {
  name: "DomainManagement",
  props: ['domains', 'permissions'],
  components: {
    Form,
    Field,
  },
  computed: {
    hasEditRight() {
      return this.permissions.find(element => element.name === 'modify domain') !== undefined
    }
  },
  methods: {
    downloadInstaller (fqdn) {
      axios.get( window.location.origin + `/api/v1/download/installer/${fqdn}`, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data])
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.setAttribute('download', 'installer.ps1');
            document.body.appendChild(link);
            link.click()
            //URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },

    getVersion(fqdn) {
      axios.get( window.location.origin + `/api/v1/domain/version/${fqdn}`, null)
          .then(response => {
            console.log(response)
            //URL.revokeObjectURL(link.href)
          }).catch(console.error)
    }
  },
}
</script>

<style scoped>

</style>

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')

import Lang from "lang.js"

//window.Vue = require('vue');
// import Vue from 'vue';
// import VueRouter from "vue-router";
// import Vuex from 'vuex';
//
// window.Vue = Vue;
import { createApp } from 'vue'

//Vue.use(VueRouter);
//Vue.use(Vuex);

import Form from "./utilities/Form"
window.Form = Form

import "bootstrap"

// import router from './routes';
import DomainManagement from "./views/DomainManagement"

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('domain-management', require('./views/DomainManagement').default)
// localize("en", en);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = createApp({})
const default_locale = window.default_language
const fallback_locale = window.fallback_locale
const messages = window.messages ?? ''

app.component('DomainManagement', DomainManagement)
app.config.globalProperties.$trans = new Lang( { messages, locale: default_locale, fallback: fallback_locale } )
app.mount('#app')
